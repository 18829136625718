.StripeFromContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 72px;
  margin-top: -600px;
  min-height: 500px;

  @media (min-width: 550px){
    margin-top: -150px;
  }
}