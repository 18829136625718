.headerImage{
  width: 470px;
  max-width: 100%;
}

.gosenHomeHeader{
  width: 100vw;
  height: 100vh;

  @media (max-width: 768px){
    height: 100%;
  }

  @media (max-height: 600px){
    height: 100%;
  }

  .bgVideoContainer{
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    @media (max-width: 768px){
      height: 100%;
    }

    @media (max-height: 600px){
      height: 100%;
    }

    .bgVideo{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -2;
      transform: translate(-50%, -50%);

      width: 100vw;
      height: 100vh;

      @media (min-aspect-ratio: 16/9) {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
      }

      @media (max-aspect-ratio: 16/9) {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
      }
    }

    .bgCoverColor{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgb(91 91 91 / 50%);
    }
  }

  .mobileVideo{
    iframe{
      border-radius: 14px;
    }
  }
}