.despreAutism{
  .despreAutismHeader{
    height: calc(600px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }

  .container{
    padding-top: 80px;
    padding-bottom: 80px;
  }
  a{
    color: rgb(242, 179, 0);
  }
}