
#ListaCampanii{
  .row-campaign{
    background: #f3f3f3;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 0px 10px 1px, rgba(0, 0, 0, 0.12) 0px 0px 14px 2px;

    img{
      border-radius: 8px;
      border: 1px solid;
    }
  }

  a{
    color: rgb(242, 179, 0);
  }
}