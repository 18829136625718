hr{
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#payment-form{
  justify-content: center;
  background-color: #ffffff;
  width: 100%;
  //min-width: 500px;
  align-self: center;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 11px 10px 38px rgb(0 0 0 / 10%);

  @media screen and (min-width: 600px) {
      width: 700px;
      max-width: 100%;
  }

  .MuiFormControl-root{
    margin-bottom: 24px;
  }
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.payment-form-sum-select{
  display: flex;
  align-items: center;
  flex-direction: column;
  .payment-form-radioGroup{
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    text-align: center;
    width: 400px;
    max-width: 100%;

    .MuiButton-root{
      width: 150px;
      border: 1px solid #ccc;
      border-radius: 30px;
      &:hover{
        color: #ffffff;
        background-color: #d32f2f;
      }
      &.Mui-checked{
        color: #ffffff;
      }
    }
  }
}

/* Buttons and links */
.payment-stripe-button-text {
  background: #1565c0;
  //font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 30px;
  border: 0;
  padding: 18px 32px;
  font-size: 16px;
  //font-weight: 600;
  cursor: pointer;
  display: block;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  //width: 100%;
  margin: auto;
  text-transform: uppercase;

  &:hover {
    filter: contrast(115%);
    background-color: #145eb2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}