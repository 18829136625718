.modalVideoModalBox{
  width: 960px;
  height: 540px;
  border-radius: 14px;
  max-width: 90%;
  max-height: 90%;

  iframe {
    border-radius: 14px;
    video{
      max-width: 100%;
      max-height: 100%;
    }
  }
}