.doneazaContent{
  .despreAutismHeader{
    height: calc(600px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }

  .container{
    padding-top: 80px;
    padding-bottom: 80px;
  }
  a{
    color: rgb(202 149 0);
    &:hover{
      color: #e51e25;
    }
  }

  h4{
    margin-bottom: 8px;
  }
}


// STRIPE CSS

//#root {
//  display: flex;
//  align-items: center;
//}
//
//body {
//  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
//  font-size: 16px;
//  -webkit-font-smoothing: antialiased;
//  display: flex;
//  justify-content: center;
//  align-content: center;
//  height: 100vh;
//  width: 100vw;
//}

//form {
//  width: 30vw;
//  min-width: 500px;
//  align-self: center;
//  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
//  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
//  border-radius: 7px;
//  padding: 40px;
//}




