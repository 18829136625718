.gosen-dropdown{
  display: flex;
  width: 150%;
  flex-direction: column;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  position: absolute;
  background-color: white;
  border-radius: 6px;
  bottom: -7px;
  transform: translateY(100%);
  padding: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;

  a {
    padding: 4px 6px 4px 6px;
    &:hover{
      background-color: #f9f9f9c4;
    }
  }

  &.gosen_dropdown_active {
    opacity: 1;
    z-index: 1;
    visibility: hidden;
  }
}